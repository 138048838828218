import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import useRouteParam from '../../hooks/useRouteParam'
import Introduction015 from './Introduction015'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0039 = (props: Props): JSX.Element => {
  const routePram = useRouteParam('https://www.kintaicloud.jp/')
  const routePram2 = useRouteParam('https://www.kintaicloud.jp/')

  return (
    <Wrapper>
      <ColumnH1 label="「育児短時間勤務（時短）」「育児時間」とは？制度を解説します" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2022.10.28</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <p>
          ワーク・ライフ・バランスや働き方改革の流れを受け、育児をしながら働く労働者は増加の一途をたどっています。
          <br />
          今回は、育児と仕事を両立するための制度のうち、比較的幼い子を育てる労働者向けの制度である
          <span>「短時間勤務制度」</span>と<span>「育児時間」</span>
          にスポットを当て、その概要や対象者、制度の詳細や取扱いについて、順を追って見ていきましょう。
        </p>
        <a href={routePram} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
        <img
          src="/images/column/details/c0039.jpg"
          alt="キンクラコラム画像39"
        />
        <h1>「短時間勤務制度」とは</h1>
        <p>
          短時間勤務制度とは、3歳未満の子供を育てる労働者の一日あたりの所定労働時間を、
          <span>原則として6時間という時短勤務</span>
          をさせることができる制度です。これは、育児・介護休業法という法律で企業に対して導入が義務づけられているもので、幼い子供を育てながら働く労働者の負担や不安を軽減させるための制度として設けられました。
        </p>
        <h2>短時間勤務制度の設定時間</h2>
        <p>
          この「原則として6時間」という意味合いとしては、たとえばフルタイム勤務が1日あたり8時間勤務である会社が「1日あたり6時間勤務を認める」としたうえで、さらに「1日あたり7時間」などの6時間を超える勤務制度や隔日勤務など、
          <span>労働者の労働時間を短縮させるための制度を導入</span>
          することが認められています。むしろ、多様な時短勤務制度を提示することで、労働者側が自身の生活スタイルに合わせた働き方を選択することができ、柔軟性の高い職場環境を整えるという効果もあります。
        </p>
        <h2>短時間勤務制度の対象者</h2>
        <p>
          短時間勤務制度の対象者は、前述の通り3歳未満の子供を育てる労働者で、
          <span>
            一日あたりの所定労働時間が6時間を超える雇用契約を交わしている者
          </span>
          です。所定労働時間が6時間以下の労働者の場合は、当然ながらこの制度を適用させる必要はありません。
          <br />
          また、日雇い労働者、1週間あたりの所定労働日数が2に以下の者、労使協定を交わして除外対象とされた者、継続雇用期間が1年未満の者、短時間勤務制度を適用する期間に育児休業を取得する者も短時間勤務制度の対象外です。さらに、すでに以前より1日あたり6時間勤務を導入している場合も、改めて短時間勤務制度を導入する必要はありません。
        </p>
        <h2>短時間勤務制度の取扱い</h2>
        <p>
          短時間勤務制度は、原則となる「1日あたりの所定労働時間が6時間」を導入しさえすれば、
          <span>その他のルールは各企業で定める</span>
          ことができます。ただし、適用対象となる労働者は幼い子供を抱える者となるため、申請期限や手続きは労働者へ過度の負担がかからないよう心がける必要があります。
          <br />
          まずは制度の詳細を社内で検討をし、固まった内容を
          <span>就業規則等でルール化</span>
          します。その際に、労使協定で適用対象者の除外設定を行う場合は、手順に沿って労働組合や労働者の代表者の意見を聴収し、締結を行います。
          <br />
          なお、給与や賞与等の賃金の取扱いについては、勤務時間にあわせた支給を行うことが一般的です。ただし、
          <span>短縮された労働時間を超える減額は認められません</span>
          。その他、短時間勤務制度を利用する労働者の待遇や人事考課などの評価を低くするなどの不利益取扱いも禁止されています。
        </p>
        {/** ここに9パターンの広告設定する ※この記事は固定表示なので全部同じものを指定しています。 */}
        {props.no === 0 && <Introduction015 />}
        {props.no === 1 && <Introduction015 />}
        {props.no === 2 && <Introduction015 />}
        {props.no === 3 && <Introduction015 />}
        {props.no === 4 && <Introduction015 />}
        {props.no === 5 && <Introduction015 />}
        {props.no === 6 && <Introduction015 />}
        {props.no === 7 && <Introduction015 />}
        {props.no === 8 && <Introduction015 />}
        <h1>「育児時間」とは</h1>
        <p>
          育児時間とは、1歳に満たない子供を育てる女性労働者が請求した場合に、企業側が付与しなければならない
          <span>「育児をするための時間」</span>
          のことです。労働基準法という法律により定められており、労働者側から請求があった場合のみ認めなければならない制度です。したがって、労働者の請求がない状態で付与する必要はありません。
        </p>
        <h2>育児時間の対象者</h2>
        <p>
          育児時間は、もともと子供への授乳やミルクのための時間として想定された制度であるため、
          <span>対象者が女性労働者に限られている</span>点に注意が必要です。
          <br />
          ただし、企業側が法律を上回る条件として、男性労働者や1歳を越える子供を育てる労働者に育児時間制度を導入することは認められています。
        </p>
        <h2>育児時間の付与時間帯</h2>
        <p>
          育児時間として取得することが可能となる時間帯は、原則として
          <span>1日2回</span>
          まで、それぞれ30分以上の時間です。この<span>30分以上の時間</span>
          を連続して取得、つまり1日に1時間の育児時間を請求することも認められます。ただし、1日あたりの
          <span>所定労働時間が4時間以下の場合は、1日1回、30分</span>
          の時間になります。
          <br />
          労働時間内のどの時間帯に育児時間の付与を求めるかについては、原則として労働者側に委ねられます。したがって、勤務開始直後や終業直前に請求があった場合でも、企業側はこれを認めなければなりません。逆に、企業側が育児時間の時間帯指定をすることも禁止されています。
          <br />
          また、育児をするためには、当然ながら職場から子供のいる場所までの移動時間を考慮しなければなりません。本来ならば、往復時間とは別に30分以上の育児時間を付与することが望ましいですが、法律上では
          <span>「往復時間を含む30分以上の時間」</span>
          が確保されていれば問題ないとされています。
        </p>
        <h2>育児時間中の給与取扱い</h2>
        <p>
          育児時間中の賃金支払いについては、法律上での決まりがないことから、企業側の判断にゆだねられます。ノーワーク・ノーペイの原則に沿えば、育児時間中の賃金支払いは義務ではないとみなされますが、いずれにしても前もって
          <span>就業規則や雇用契約書で詳細を定めておく</span>必要があります。
        </p>
        <h1>短時間勤務制度と育児時間の併用</h1>
        <p>
          短時間勤務制度と育児時間は、どちらも幼い子供を育児するために認められている制度です。しかし、労働者がそれぞれの制度を希望する目的が異なるため、
          <span>制度を併用することが認められています</span>
          。したがって、たとえば短時間勤務をしている労働者が育児時間の請求を行った場合、短時間勤務制度を適用していることを理由に請求を拒むことはできない点に注意しましょう。
        </p>
        <h1>まとめ</h1>
        <p>
          令和4年4月より、改正された育児・介護休業法の施行が開始されています。同時に、各企業には男性の育児休業取得の促進や、より育児休業を取得しやすい環境づくりが求められます。
          <br />
          育児短時間勤務や育児時間は、育児休業などと比較すると認知度が高くないものとなりますが、どちらも育児をする労働者が安心して働き続けるために非常に有効な制度です。まずは社内制度を洗い出し、労働者が取得しやすいようにルール化をしてみましょう。
        </p>
        <a href={routePram2} className="column-detail-link">
          ⇒
          どんな勤務時間にも対応できる勤怠管理システム「キンクラ」についてはここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0039
